import { tv, type VariantProps } from "../../../util/tv";
import { link, solid, outline, ghost } from "./button.colors";

export const buttonVariant = tv({
  slots: {
    base: [
      "inline-flex",
      "items-center",
      "box-border",
      "no-underline",
      "appearance-none",
      "outline-none",
      "select-none",
      "subpixel-antialiased",
      "overflow-hidden",
      "tap-highlight-transparent",
      "transition-colors duration-300",
      "disabled:pointer-events-none",
      "disabled:opacity-50",
      "focus-visible:z-10",
      "focus-visible:outline-2",
      "focus-visible:outline-focus",
    ],
    image: ["max-w-md", "pointer-events-none"],
    subTextWrapper: ["flex flex-col", "text-left"],
    subText: ["text-display-10 leading-none", "rtl:ml-auto"],
    icon: ["outline-none", "shrink-0"],
  },
  variants: {
    appearance: {
      link: "",
      solid: "",
      outline: "",
      ghost: "",
    },
    color: {
      white: "",
      black: "",
      blackWithBorder: "",
      gray: "",
      grayDark: "",
      twoGrays: "",
      blue: "",
      blueDark: "",
    },
    size: {
      xxs: {
        base: "text-display-12 gap-x-1.5 rounded-md",
        icon: "w-3 h-3",
      },
      xs: {
        base: ["text-display-12", "gap-x-2"],
        icon: ["w-3 h-3"],
      },
      sm: {
        base: ["text-display-14", "gap-x-2"],
        icon: ["w-4 h-4"],
      },
      md: {
        base: ["text-display-16", "gap-x-2"],
        icon: ["w-5 h-5"],
      },
      lg: {
        base: ["text-display-20", "gap-x-2"],
        icon: ["w-6 h-6"],
      },
      store: {
        base: ["text-display-18", "gap-x-2"],
        icon: ["w-7 h-7"],
      },
    },
    weight: {
      regular: "font-regular",
      medium: "font-medium",
      semibold: "font-semibold",
      bold: "font-bold",
    },
    fluid: {
      none: {
        base: [],
      },
      all: {
        base: ["flex w-full"],
      },
      mobile: {
        base: ["flex w-full md:w-auto"],
      },
      desktop: {
        base: ["flex w-auto lg:w-full"],
      },
    },
    rounded: {
      true: "rounded-full",
    },
    isImage: {
      false: "transition-colors duration-300",
      true: "",
    },
    isIconOnly: {
      true: "flex justify-center",
    },
    wrap: {
      false: "whitespace-nowrap",
      true: "whitespace-normal",
    },
  },
  compoundVariants: [
    // button
    {
      appearance: ["solid", "outline", "ghost"],
      class: {
        base: "border border-transparent justify-center text-center",
      },
    },

    // link
    {
      appearance: ["link"],
      class: {
        base: "rounded-2xs",
      },
    },

    // link / color
    {
      appearance: "link",
      color: "white",
      class: {
        base: link.white,
      },
    },
    {
      appearance: "link",
      color: "black",
      class: {
        base: link.black,
      },
    },
    {
      appearance: "link",
      color: "gray",
      class: {
        base: link.gray,
      },
    },
    {
      appearance: "link",
      color: "grayDark",
      class: {
        base: link.grayDark,
      },
    },
    {
      appearance: "link",
      color: "twoGrays",
      class: {
        base: link.twoGrays,
      },
    },
    {
      appearance: "link",
      color: "blue",
      class: {
        base: link.blue,
      },
    },
    {
      appearance: "link",
      color: "blue",
      class: {
        base: link.blueDark,
      },
    },

    // solid / color
    {
      appearance: "solid",
      color: "white",
      class: {
        base: solid.white,
      },
    },
    {
      appearance: "solid",
      color: "black",
      class: {
        base: solid.black,
      },
    },
    {
      appearance: "solid",
      color: "blackWithBorder",
      class: {
        base: [solid.black, "border-[0.8px] border-gray-500"],
      },
    },
    {
      appearance: "solid",
      color: "gray",
      class: {
        base: solid.gray,
      },
    },
    {
      appearance: "solid",
      color: "grayDark",
      class: {
        base: solid.grayDark,
      },
    },
    {
      appearance: "solid",
      color: "twoGrays",
      class: {
        base: solid.twoGrays,
      },
    },
    {
      appearance: "solid",
      color: "blue",
      class: {
        base: solid.blue,
      },
    },
    {
      appearance: "solid",
      color: "blueDark",
      class: {
        base: solid.blueDark,
      },
    },

    // outline / color
    {
      appearance: "outline",
      color: "white",
      class: {
        base: outline.white,
      },
    },
    {
      appearance: "outline",
      color: "black",
      class: {
        base: outline.black,
      },
    },
    {
      appearance: "outline",
      color: "gray",
      class: {
        base: outline.gray,
      },
    },
    {
      appearance: "outline",
      color: "grayDark",
      class: {
        base: outline.grayDark,
      },
    },
    {
      appearance: "outline",
      color: "twoGrays",
      class: {
        base: outline.twoGrays,
      },
    },
    {
      appearance: "outline",
      color: "blue",
      class: {
        base: outline.blue,
      },
    },
    {
      appearance: "outline",
      color: "blueDark",
      class: {
        base: outline.blueDark,
      },
    },

    // ghost / color
    {
      appearance: "ghost",
      color: "white",
      class: {
        base: ghost.white,
      },
    },
    {
      appearance: "ghost",
      color: "black",
      class: {
        base: ghost.black,
      },
    },
    {
      appearance: "ghost",
      color: "gray",
      class: {
        base: ghost.gray,
      },
    },
    {
      appearance: "ghost",
      color: "grayDark",
      class: {
        base: ghost.grayDark,
      },
    },
    {
      appearance: "ghost",
      color: "twoGrays",
      class: {
        base: ghost.twoGrays,
      },
    },
    {
      appearance: "ghost",
      color: "blue",
      class: {
        base: ghost.blue,
      },
    },
    {
      appearance: "ghost",
      color: "blueDark",
      class: {
        base: ghost.blueDark,
      },
    },

    // Focus
    {
      appearance: ["solid", "outline", "ghost"],
      className: "lg:focus-visible:outline-offset-2",
    },
    {
      appearance: "link",
      className: "lg:focus-visible:outline-offset-8",
    },
    {
      color: ["white", "black", "gray", "grayDark", "blue", "blueDark"],
      className: {
        base: "focus-visible:outline-blue-400",
      },
    },

    // button / size
    {
      appearance: ["solid", "outline", "ghost"],
      size: "xxs",
      isImage: false,
      class: {
        base: "px-2.5 h-8",
      },
    },
    {
      appearance: ["solid", "outline", "ghost"],
      size: "xs",
      isImage: false,
      isIconOnly: false,
      class: {
        base: ["px-3 h-10", "rounded-md"],
      },
    },
    {
      appearance: ["solid", "outline", "ghost"],
      size: "sm",
      isImage: false,
      isIconOnly: false,
      class: {
        base: ["px-4 h-11", "rounded-xl"],
      },
    },
    {
      appearance: ["solid", "outline", "ghost"],
      size: "md",
      isImage: false,
      isIconOnly: false,
      class: {
        base: ["px-6 h-12", "rounded-xl"],
      },
    },
    {
      appearance: ["solid", "outline", "ghost"],
      size: "lg",
      isImage: false,
      isIconOnly: false,
      class: {
        base: ["px-6 h-14", "rounded-xl"],
      },
    },
    {
      appearance: ["solid", "outline", "ghost"],
      size: "store",
      isImage: false,
      isIconOnly: false,
      class: {
        base: ["px-3.5 h-13", "rounded-lg", "leading-none"],
      },
    },

    // isIconOnly
    {
      isIconOnly: true,
      isImage: false,
      size: "xxs",
      class: {
        base: "w-8 p-0",
      },
    },
    {
      isIconOnly: true,
      isImage: false,
      size: "xs",
      class: {
        base: "w-10 h-10",
      },
    },
    {
      isIconOnly: true,
      isImage: false,
      size: "sm",
      class: {
        base: "w-11 h-11",
      },
    },
    {
      isIconOnly: true,
      isImage: false,
      size: "md",
      class: {
        base: "w-12 h-12",
      },
    },
    {
      isIconOnly: true,
      isImage: false,
      size: "lg",
      class: {
        base: "w-14 h-14",
      },
    },
    {
      appearance: "link",
      isIconOnly: true,
      isImage: false,
      class: {
        base: ["w-auto h-auto"],
      },
    },

    // appearance / size / color
    {
      color: ["twoGrays"],
      appearance: ["solid", "outline", "ghost"],
      size: "xxs",
      isImage: false,
      className: {
        base: ["px-2.5 py-[6px]", "text-display-14 font-medium"],
      },
    },
  ],
  defaultVariants: {
    appearance: "link",
    size: "md",
    color: "white",
    weight: "semibold",
    isImage: false,
    isIconOnly: false,
    wrap: false,
  },
});

export type ButtonVariantProps = VariantProps<typeof buttonVariant>;

export type ButtonReturnVariantProps = ReturnType<typeof buttonVariant>;
